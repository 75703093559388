





























import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ReloadButton from '@/components/BaseReloadButton.vue';
import Grid from '@/components/Grid.vue';
import GridFileItem from '@/components/GridFileItem.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { FileActions } from '@/store/file/actions.enum';
import OpenFileMixin from '@/mixins/open-file';
import IntervalReloadMixin from '@/mixins/interval-reload';

export default Vue.extend({
  name: 'Highlights',

  mixins: [OpenFileMixin, IntervalReloadMixin],

  components: {
    ReloadButton,
    Grid,
    GridFileItem,
    LoadingSpinner,
  },

  computed: {
    ...mapGetters('file', ['items', 'loading']),
  },

  methods: {
    ...mapActions('file', { fetchHighlights: FileActions.FETCH_HIGHLIGHTS }),

    reload() {
      this.fetchHighlights();
    },
  },
});
